<template>
  <div class="fundo">
    <div class="container-fluid h-100">
      <img v-if="conlaser == 0" src="/img/logo_branca.svg" alt="Logo" class="logo">
      <img v-else src="/img/logo-conlaser.webp" alt="Logo" class="logo">
      <div class="row h-100 align-items-center">
        <div class="col-12 text-center" v-if="loading">
          <img style="max-width: 100px;" src="/img/loading.gif" alt="Carregando" />
        </div>
        <div class="col-12" v-else>
          <div class="row align-items-center h-100">
            <div class="col-md-6 col-12">
              <div class="text-center">
                <div class="nomeUnidade">
                 {{conlaser == 1 ? '' : 'OdontoTop'}} {{factory.name ? factory.name : 'Franchising'}}
                </div>
                <div class="metaTitulo" style="color: #ff00d4">
                  Meta mensal
                </div>
                <div class="metaMensalValor">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaMensal"
                    :format="theFormat"
                    :duration="5"
                    :delay="1"
                    easing="Power1.easeOut"/>
                </div>
                <div class="metaMensalValorAtingido">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaMensalValor"
                    :format="theFormat"
                    :duration="5"
                    :delay="1"
                    easing="Power1.easeOut"/> <label>= {{metaMensalPorcentagem}}%</label>
                </div>
                <div class="metaTitulo" style="color: #a0ff03">
                  Meta semanal
                </div>
                <div class="metaSemanalValor">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaSemanal"
                    :format="theFormat"
                    :duration="4"
                    :delay="1"
                    easing="Power1.easeOut"/>
                </div>
                <div class="metaSemanalValorAtingido">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaSemanalValor"
                    :format="theFormat"
                    :duration="4"
                    :delay="1"
                    easing="Power1.easeOut"/> <label>= {{metaSemanalPorcentagem}}%</label>
                </div>
                <div class="metaTitulo" style="color: #1ad5de">
                  Meta diária
                </div>
                <div class="metaDiariaValor">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaDiaria"
                    :format="theFormat"
                    :duration="3"
                    :delay="1"
                    easing="Power1.easeOut"/>
                </div>
                <div class="metaDiariaValorAtingido">
                  <label>R$</label>
                  <number
                    :from="0"
                    :to="metaDiariaValor"
                    :format="theFormat"
                    :duration="3"
                    :delay="1"
                    easing="Power1.easeOut"/> <label>= {{metaDiariaPorcentagem}}%</label>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div ref="chart" id="gerarChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="area_footer">
      <marquee width="100%" direction="right" scrollamount="10" behavior="alternate" loop="infinite" class="titulo_super">
        <span v-for="index in 20" :key="index">
          <span class="pl-1"></span> MÊS DE {{ moment().locale('pt-br').format('MMMM') }} <span class="pr-1"></span>| 
        </span>
      </marquee>
    </div>
  </div>
</template>

<script>
import VueNumber from 'vue-number-animation'
import moment from 'moment'
import axios from 'axios'
import './d3.js'
// import VueCircle from 'vue2-circle-progress'
export default {
  props: ['factory'],
  components: {
    VueNumber,
  },
  data() {
    return {
      loading: true,
      items: [],
      colors: ["#ff00d4", "#a0ff03", "#1ad5de"],
      ranDataset: [
        {index: 0, name: 'move', icon: "M", percentage: 0},
        {index: 1, name: 'exercise', icon: "S", percentage: 0},
        {index: 2, name: 'stand', icon: "D", percentage: 0}
      ],
      metaMensal: 0,
      metaMensalValor: 0,
      metaMensalPorcentagem: 0,
      metaSemanal: 0,
      metaSemanalValor: 0,
      metaSemanalPorcentagem: 0,
      metaDiaria: 0,
      metaDiariaValor: 0,
      metaDiariaPorcentagem: 0,
      conlaser: ''
    }
  },
  methods: {
    moment,
    theFormat(number) {
      const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      return new Intl.NumberFormat('pt-BR', options).format(parseFloat(number))
    },
    loadContent () {
      this.loading = true
      this.getDados(true)
    },
    async getDados (carregar) {
      let dados = {
        factory_id: this.factory.id ? this.factory.id : 999999,
        periodo: moment().format('Y-MM'),
      }
      axios
      .post('/api/faturamentoTotalUnidades', dados)
      .then(response => {
          let valorRetornoMensal = 0
          if (response.data.meta_mes > 0 && response.data.valor_mes > 0 ) {
            let porcentagemMensal = (response.data.valor_mes / response.data.meta_mes) * 100
            valorRetornoMensal = Math.min(Math.max(porcentagemMensal, 0), 100)
            valorRetornoMensal = Number(valorRetornoMensal).toFixed(0)
          }
          let valorRetornoSemanal = 0
          if (response.data.meta_semana > 0  && response.data.valor_semana > 0 ) {
            let porcentagemSemanal = (response.data.valor_semana / response.data.meta_semana) * 100
            valorRetornoSemanal = Math.min(Math.max(porcentagemSemanal, 0), 100)
            valorRetornoSemanal = Number(valorRetornoSemanal).toFixed(0)
          }

          let valorRetornoDiaria = 0
          if (response.data.meta_dia > 0  && response.data.valor_dia > 0 ) {
            let porcentagemDiaria = (response.data.valor_dia / response.data.meta_dia) * 100
            valorRetornoDiaria = Math.min(Math.max(porcentagemDiaria, 0), 100)
            valorRetornoDiaria = Number(valorRetornoDiaria).toFixed(0)
          }

          this.metaMensal = response.data.meta_mes
          this.metaMensalValor = response.data.valor_mes
          this.metaMensalPorcentagem = valorRetornoMensal
          this.metaSemanal = response.data.meta_semana
          this.metaSemanalValor = response.data.valor_semana
          this.metaSemanalPorcentagem = valorRetornoSemanal
          this.metaDiaria = response.data.meta_dia
          this.metaDiariaValor = response.data.valor_dia
          this.metaDiariaPorcentagem = valorRetornoDiaria
          this.conlaser = response.data.conlaser

          // console.log('this.metaMensal', this.metaMensal)
          // console.log('this.metaMensalValor', this.metaMensalValor)
          // console.log('this.metaMensalPorcentagem', this.metaMensalPorcentagem)
          // console.log('this.metaSemanal', this.metaSemanal)
          // console.log('this.metaSemanalValor', this.metaSemanalValor)
          // console.log('this.metaSemanalPorcentagem', this.metaSemanalPorcentagem)
          // console.log('this.metaDiaria', this.metaDiaria)
          // console.log('this.metaDiariaValor', this.metaDiariaValor)
          // console.log('this.metaDiariaPorcentagem', this.metaDiariaPorcentagem)

          this.loading = false
          if (carregar) {
            setTimeout(() => {
              this.ranDataset = [
                {index: 0, name: 'mes', icon: "M", percentage: Number(this.metaMensalPorcentagem)},
                {index: 1, name: 'semana', icon: "S", percentage: Number(this.metaSemanalPorcentagem)},
                {index: 2, name: 'dia', icon: "D", percentage: Number(this.metaDiariaPorcentagem)}
              ]
              this.iniciaGrafico()
            }, 1000)
          }
          this.timeoutAtt()
          console.log('nova request')
          console.log(response)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    timeoutAtt() {
      setTimeout(() => {
        this.getDados()
        this.ranDataset = [
          {index: 0, name: 'mes', icon: "M", percentage: Number(this.metaMensalPorcentagem)},
          {index: 1, name: 'semana', icon: "S", percentage: Number(this.metaSemanalPorcentagem)},
          {index: 2, name: 'dia', icon: "D", percentage: Number(this.metaDiariaPorcentagem)}
        ]
        this.atualizar(this.field, this.ranDataset, 2, this.colors)
      }, 180000)
    },
    iniciaGrafico () {
      var gap = 2;
      var colors = ["#ff00d4", "#a0ff03", "#1ad5de"];
      var width = 550,
      height = 550,
      τ = 2 * Math.PI;
      var background = d3.svg.arc()
        .startAngle(0)
        .endAngle(τ)
        .innerRadius(function (d, i) {
          return 160 - d.index * (40 + gap)
        })
        .outerRadius(function (d, i) {
          return 200 - d.index * (40 + gap)
        });
      var svg = d3.select("#gerarChart").append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
      var gradient = svg.append("svg:defs")
        .append("svg:linearGradient")
        .attr("id", "gradient")
        .attr("x1", "0%")
        .attr("y1", "100%")
        .attr("x2", "50%")
        .attr("y2", "0%")
        .attr("spreadMethod", "pad");
      gradient.append("svg:stop")
        .attr("offset", "0%")
        .attr("stop-color", "#ff00d4")
        .attr("stop-opacity", 1);
      gradient.append("svg:stop")
        .attr("offset", "100%")
        .attr("stop-color", "#ff00d4")
        .attr("stop-opacity", 1);
      var defs = svg.append("defs");
      var filter = defs.append("filter")
        .attr("id", "dropshadow")
      filter.append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 4)
        .attr("result", "blur");
      filter.append("feOffset")
        .attr("in", "blur")
        .attr("dx", 1)
        .attr("dy", 1)
        .attr("result", "offsetBlur");
      var feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode")
        .attr("in", "offsetBlur");
      feMerge.append("feMergeNode")
        .attr("in", "SourceGraphic");
      this.field = svg.selectAll("g")
        .data(this.ranDataset)
        .enter().append("g");
      this.field.append("path").attr("class", "progress").attr("filter", "url(#dropshadow)");
      this.field.append("path").attr("class", "bg")
        .style("fill", function (d) {
          return colors[d.index];
        })
        .style("opacity", 0.2)
        .attr("d", background);
      this.field.append("text").attr('class','icon');
      d3.transition().duration(1750).each(this.atualizar(this.field, this.ranDataset, gap, colors));
    },
    atualizar (field, rand, gap, colors) {
      field = field
        .each(function (d) {
          this._value = d.percentage;
        })
        .data(rand)
        .each(function (d) {
          d.previousValue = this._value;
        });
      field.select("path.progress").transition().duration(1750).delay(function (d, i) {
        return i * 200
      })
      .ease("elastic")
      .attrTween("d", this.arcTween)
      .style("fill", function (d) {
        if(d.index===0){
          return "url(#gradient)"
        }
        return colors[d.index];
      });
      field.select("text.icon").text(function (d) {
        return d.icon;
      }).attr("transform", function (d) {
        return "translate(10," + -(172 - d.index * (40 + gap)) + ")"
      });
      field.select("text.completed").text(function (d) {
        return Math.round(d.percentage /100 * 600);
      });
      // setTimeout(this.atualizar(field, rand, gap, colors), 2000)
    },
    arcTween (d) {
      var gap = 2;
      var τ = 2 * Math.PI;
      var arc = d3.svg.arc()
        .startAngle(0)
        .endAngle(function (d) {
          return d.percentage / 100 * τ;
        })
        .innerRadius(function (d) {
          return 160 - d.index * (40 + gap)
        })
        .outerRadius(function (d) {
          return 200 - d.index * (40 + gap)
        })
        .cornerRadius(20);
      var i = d3.interpolateNumber(d.previousValue, d.percentage);
      return function (t) {
        d.percentage = i(t);
        return arc(d);
      };
    }
  },
  created () {
    this.loadContent()
  }
}
</script>

<style lang="scss" scoped>
.fundo {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: auto;
  position: relative;
  background-color: #1c1c1e;
}
.area_footer {
  bottom: 0;
  background: #ffc911;
  height: 18px;
  position: fixed;
  width: 100%;
  z-index: 20;
}
.titulo_super {
  color: #102945;
  left: 0;
  font-family: 'Raleway';
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  position: absolute;
  padding: 0 3px;
  text-transform: uppercase;
  top: 0px;
}
.nomeUnidade {
  color: white;
  font-family: 'Raleway';
  font-size: 38px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 35px;
  text-transform: uppercase;
  @media (max-width: 1480px){
    font-size: 30px;
  }
}
.metaTitulo {
  color: white;
  font-family: 'Raleway';
  font-size: 36px;
  font-weight: bold;
  margin: 30px 0 20px 0;
  text-transform: uppercase;
  @media (max-width: 1480px){
    margin: 20px 0;
    font-size: 28px;
  }
}
.metaMensalValorAtingido {
  font-size: 34px;
  font-weight: bold;
  color: #ff00d4;
  @media (max-width: 1480px){
    font-size: 26px;
  }
  label {
    font-size: 22px;
  }
}
.metaMensalValor {
  font-size: 16px;
  font-weight: bold;
  color: #ff00d4;
  margin-bottom: 5px;
  label {
    font-size: 14px;
  }
}
.metaSemanalValorAtingido {
  font-size: 34px;
  font-weight: 900;
  color: #a0ff03;
  @media (max-width: 1480px){
    font-size: 26px;
  }
  label {
    font-size: 22px;
  }
}
.metaSemanalValor {
  font-size: 16px;
  font-weight: bold;
  color: #a0ff03;
  margin-bottom: 5px;
  label {
    font-size: 14px;
  }
}
.metaDiariaValorAtingido {
  font-size: 34px;
  font-weight: bold;
  color: #1ad5de;
  @media (max-width: 1480px){
    font-size: 26px;
  }
  label {
    font-size: 22px;
  }
}
.metaDiariaValor {
  font-size: 16px;
  font-weight: bold;
  color: #1ad5de;
  margin-bottom: 5px;
  label {
    font-size: 14px;
  }
}
.logo {
  max-width: 250px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>