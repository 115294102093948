<template>
  <div class="box box-widget">
    <div class="box-header">
      <div class="row">
        <div class="col-md-3 col-sm-4 col-12">
          <h3 class="box-title">{{title}}</h3>
        </div>
        <div v-if="busca != 'false'" class="col-lg-9 col-sm-9 col-12">
          <form method="GET" class="form-horizontal" :action="url">
            <div class="input-group flatpickr align-items-center">
              <a
                class="btn btn-flat btn-sm btn-info mr-1"
                href="/meta-venda/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Vendas por hora"
                v-if="show_call"
              >
                <i class="fa fa-money"></i> Vendas por hora
              </a>
              <!-- <a
                class="btn btn-flat btn-sm btn-info mr-1"
                href="/meta-unidade/1a3b8080f194aeb0751491c16ff646be"
                target="_blank"
                data-toggle="tooltip"
                title="Meta mensal"
                v-if="show_call"
              >
                <i class="fa fa-pie-chart"></i> Meta mensal
              </a> -->
              <a
                class="btn btn-flat btn-sm btn-primary mr-1"
                href="/ligacoes/dashboard/1cb72621f9650045e697e7c237e1412e"
                target="_blank"
                data-toggle="tooltip"
                title="Dashboard Ligações"
                v-if="show_call"
              >
                <i class="fa fa-phone"></i> Dash Ligações
              </a>
              <a
                class="btn btn-flat btn-sm btn-warning mr-1"
                href="/dashboard/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Dashboard Geral"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Dash Geral
              </a>
              <!-- <a
                class="btn btn-flat btn-sm btn-warning mr-1"
                href="/meta-unidade/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Meta Mensal Semanal e Diária Franchising"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Meta Franchising
              </a> -->
              <a
                class="btn btn-flat btn-sm btn-info mr-1"
                href="/metas-unidades/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Metas Mensais Unidades"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Meta Unidades
              </a>
              <a
                class="btn btn-flat btn-sm btn-success mr-1"
                href="/super-sexta-unidades/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Super Sexta Unidades"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Super Sexta Uni.
              </a>
              <a
                class="btn btn-flat btn-sm btn-success mr-1"
                href="/super-sexta/52876260c54ebf89ed572e0eb8465dcf"
                target="_blank"
                data-toggle="tooltip"
                title="Super Sexta Geral"
                v-if="factoryList"
              >
                <i class="fa fa-bar-chart"></i> Super Sexta
              </a>
              <div class="mt-2 ml-n5 mr-2" v-if="filter_prod == true || filter_prod ==  false">
                <label for="status" class="col-sm-0 control-label">Sem produtos</label>
                <input type="checkbox" name="filter_prod" :value="1" :checked="filter_prod"/>
              </div>
              
              <input
                v-if="calendar == true"
                type="text"
                name="busca"
                class="form-control mouse-alter datepicker"
                placeholder="Buscar por data"
                id="inputDate"
                :value="busca"                
              />

              <input
                v-else
                type="text"
                name="busca"
                class="form-control"
                placeholder="Buscar por"
                :value="busca"
              />

              <span class="input-group-btn">
                <button type="submit" class="btn btn-primary btn-flat">
                  <i class="fa fa-search"></i>
                </button>
                <a
                  :href="url"
                  data-tooltip="Limpar Busca" data-flow="top"
                  class="btn btn-default btn-flat"
                >
                  <i class="fa fa-times"></i>
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="box-body">
      <slot></slot>

      <form
        v-if="items.data.length > 0"
        :class="formClass"
        v-on:submit.prevent="confirmDelete"
        :action="url + '/destroy'"
        method="POST"
      >
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">{{title}}</th>
              <th v-if="actions != 'false'">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input class="checkbox-delete" type="checkbox" name="registro[]" :value="item.id" />
              </th>

              <td
              
                v-for="(field, index) in item"
                :key="index"
                class="v-middle"
                :style="isMultiBadge(field) ? 'max-width: 300px' : ''"
              >
                <span v-if="field === null">{{field}}</span>
                <span v-else-if="!field.type&& !Array.isArray(field)">{{field}}</span>
                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width:150px; max-height:150px;" />
                </span>
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field)"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                >
                  <span>{{campo.text}}</span>
                </span>
                <span
                  v-else-if="field.type == 'badge'"
                  class="badge"
                  :class="'badge-' + field.status"
                >{{ field.text }}</span>

                <span
                  v-else-if="field.type == 'action'"
                  v-for="(action, index) in item.actions"
                  :key="index"
                >
                  <a
                    data-toggle="tooltip"
                    class="btn btn-flat ml-10"
                    :class="'btn-' + action.color"
                    :href="action.path"
                    :title="action.label"
                  >
                    <i :class="action.icon"></i>
                  </a>&nbsp;
                </span>
              </td>
             
              <td v-if="!item.actions && actions != 'false'" class="v-middle">
                <a
                  class="btn btn-flat btn-primary"
                  :href="url + '/' + item.id + '/edit'"
                  data-toggle="tooltip"
                  title="Editar"
                >
                  <i class="fa fa-pencil"></i>
                </a>
                <a
                  class="btn btn-flat btn-warning"
                  :href="'/dashboard/' + item.status.hash"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Dashboard"
                  v-if="factoryList"
                >
                  <i class="fa fa-bar-chart"></i>
                </a>
                <a
                  class="btn btn-flat btn-success"
                  :href="'/super-sexta/' + item.status.hash"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Super sexta"
                  v-if="factoryList"
                >
                  <i class="fa fa-money"></i>
                </a>
                <a
                  class="btn btn-flat btn-info"
                  :href="'/meta-unidade/' + item.status.hash"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Dashboard Meta Mensal"
                  v-if="factoryList"
                >
                  <i class="fa fa-pie-chart"></i>
                </a>
                <!-- <span
                  class="badge badge-danger ml-1"
                  v-if="factoryList && !item.status.usuario_sistema"
                >
                  Sem sistema
                </span> -->
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="(titles.length + 2)" align="left" vertical-align="center">
                <button
                  v-if="!notDeletable"
                  type="submit"
                  class="mv-22 btn btn-flat btn-danger"
                  :disabled="btnDelete.disabled"
                >
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
                <slot name="pagination"></slot>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>

      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
  </div>
</template>

<style>
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}
</style>

<script>
export default {
  props: [
    "calendar",
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "factoryList",
    "mask",
    "show_call"
  ],
  data: function() {
    return {
      deleteItems: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false
      }
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function() {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function(e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!"
      }).then(result => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    }
  }
};
</script>